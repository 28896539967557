import * as bootstrap from 'bootstrap';
import React from 'react';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ScrollToTop from './components/common/scrollToTop';
import ScrollToTopBtn from './components/common/scrollToTopBtn';
import Footer from './components/footer';
import NavBar from './components/navbar';
import MainContext from './context/mainContext';
import useAllBlogs from './hooks/useAllBlogs';
import useAllResources from './hooks/useAllResources';
import useInitData from './hooks/useInitData';
import useMediaQuery from './hooks/useMediaQuery';
import useUserData from './hooks/useUserData';
import generateRoutes, { getIsGlobal } from './routes/backOfficeRoutes';
import http from './services/httpService';
import TimerProvider from './timerProvider/timerProvider';

function App() {
	const location = useLocation();
	const [initData, setInitData] = useInitData();
	const [userData, setUserData] = useUserData();
	const [mediaQuery] = useMediaQuery();
	const [blogs, setBlogs] = useAllBlogs();
	const [resources, setResources] = useAllResources();

	const { protectedRoutesWithProps, protectedRoutes, routes } = generateRoutes(
		initData.underMaintenance
	);
	const isGlobal = getIsGlobal(location.pathname);

	const handleUserDataChanged = (data) => setUserData((prevData) => ({ ...prevData, ...data }));
	const handleInitDataChanged = (data) => setInitData((prevData) => ({ ...prevData, ...data }));
	const handleResetBlogs = () => setBlogs(null);
	const handleRemoveBlog = (_id) => setBlogs(blogs.filter((b) => b._id !== _id));

	const handleResetResources = () => setResources(null);
	const handleRemoveResource = (_id) => setResources(resources.filter((r) => r._id !== _id));

	const handleScroll = async (el) => {
		if (mediaQuery === 'xs' || mediaQuery === 'sm' || mediaQuery === 'md') {
			const myCollapse = document.getElementById('navbarSupportedContent');
			await new bootstrap.Collapse(myCollapse, { show: false });
		}

		const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
		const myNav = document.getElementById('myNav');
		const yOffset = -myNav.offsetHeight;
		window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
	};

	return (
		<MainContext.Provider
			value={{
				initData,
				userData,
				mediaQuery,
				blogs,
				resources,
				onLogout: () => http.logout(),
				onInitDataChanged: handleInitDataChanged,
				onUserDataChanged: handleUserDataChanged,
				onResetBlogs: handleResetBlogs,
				onRemoveBlog: handleRemoveBlog,
				onResetResources: handleResetResources,
				onRemoveResource: handleRemoveResource,
				onHashLinkClick: handleScroll,
			}}>
			<TimerProvider sessionTime={initData.sessionTime}>
				<ToastContainer
					position='bottom-right'
					theme='colored'
				/>
				<ScrollToTopBtn showBelow={250} />
				<ScrollToTop />
				<NavBar isGlobal={isGlobal} />
				<Switch>
					{protectedRoutesWithProps}
					{protectedRoutes}
					{routes}
					<Redirect to='/notFound' />
				</Switch>
				<Footer isGlobal={isGlobal} />
			</TimerProvider>
		</MainContext.Provider>
	);
}

export default App;
