import React, { useState } from 'react';
import Countdown from 'react-countdown';
import useMain from '../../../../hooks/useMain';
import Image from '../../../common/image';
import { TextCard } from '../../ingredients/cards';

const Festivals = () => {
	const { initData, uiInfo } = useMain();
	const { saleEvents } = initData;
	const { isLessThanMD } = uiInfo;

	const firstDay = saleEvents[0];
	const lastDay = saleEvents[saleEvents.length - 1];

	const [isFinished, setIsFinished] = useState(Date.now() >= lastDay.endTime);
	const currentDay = saleEvents.find((s) => Date.now() > s.startTime && Date.now() <= s.endTime);

	if (Date.now() < firstDay.startTime || Date.now() > lastDay.endTime + 2 * 24 * 60 * 60 * 1000)
		return null;
	return (
		<div className='row'>
			<div className='col-sm-12'>
				<TextCard
					className='goldenFuture'
					text={
						<div className='row py-4 py-lg-3 gy-5 align-items-center text-light'>
							<div className='col-lg-4 text-center'>
								<Image
									className='w-100 d-none d-lg-inline-block'
									fileName='festival/goldenFuture/logo.webp'
								/>
								<Image
									className='w-75 d-inline-block d-lg-none'
									fileName='festival/goldenFuture/logoMob.webp'
								/>
							</div>
							<div className='col-lg-4 text-center'>
								{isFinished ? (
									<h4>Event is ended.</h4>
								) : (
									<>
										<div>
											<div>Event ends in:</div>
											<Countdown
												className='fs-1'
												onComplete={() => setIsFinished(true)}
												date={lastDay.endTime}
											/>
										</div>
										{currentDay && (
											<div className='fs-7 mt-3'>
												Today's Bonus:{' '}
												<b>{parseInt(currentDay?.percentage * 100)}%</b>
											</div>
										)}
									</>
								)}
							</div>

							<div className='col-lg-4 text-center'>
								<Image
									className={`w-${isLessThanMD ? '100' : '75'}`}
									fileName='festival/goldenFuture/date2.webp'
								/>
							</div>
						</div>
					}
				/>
			</div>
		</div>
	);
};

export default Festivals;
