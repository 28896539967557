import React, { useState } from 'react';
import Countdown from 'react-countdown';
import config from '../../../config';
import styles from '../../../css/dashboard/ingredients.module.css';
import useMain from '../../../hooks/useMain';
import { numberWithCommas } from '../../../utils/helpers';
import { paginate } from '../../../utils/paginate';
import Pagination from '../../common/pagination';
import { TextCard } from '../ingredients/cards';
import InvestmentDashboardTable from '../ingredients/investmentDashboardTable';

const InvestmentDashboard = () => {
	const { initData, userData } = useMain();
	const { data, sum, isLimited, xSum } = userData.investData;
	const { currentPackageValue, usedMaxCap, maxCap } = userData;
	const [currentPage, setCurrentPage] = useState(1);

	const sorted = [...data].sort((a, b) => (a < b ? 1 : -1));
	const tableData = paginate(sorted, currentPage, config.tablePageSize);

	return (
		<div className='row'>
			<div className='col-12'>
				<TextCard
					titleClass={''}
					title={''}
					text={
						<>
							<div className='row'>
								<div className='col text-center'>
									<h4>Next Staking Distribution Time:</h4>
									<Countdown
										className='h2'
										onComplete={() => window.location.reload()}
										date={initData.stakingTime + 15000}
									/>
								</div>
							</div>
							<hr />
							{userData.card === 'white' ? (
								<div className='row my-5'>
									<div className='col text-center'>
										<h4>You do not have any active package right now.</h4>
									</div>
								</div>
							) : (
								<div className='row my-5'>
									<div className='col-sm-6 offset-sm-3 col-12 text-center'>
										<h4 className='mb-4'>My Cumulative Active Investments:</h4>
										<InvestmentDashboardTable data={tableData} />
										<Pagination
											customClass={`mt-1 ${styles.myPagination}`}
											activeClass={styles.myActive}
											totalCount={sorted.length}
											pageSize={config.tablePageSize}
											currentPage={currentPage}
											onPageChange={setCurrentPage}
										/>

										{/* <h5 className='mt-4'>
											This month total profit: ${numberWithCommas(sum)}
										</h5> */}

										<div className='alert alert-info my-5 text-center'>
											<div className='mb-3'>
												<div className='d-flex align-items-center justify-content-center'>
													<div>
														<div>
															<b>Total Package Value</b>
														</div>{' '}
														<div>
															($
															{numberWithCommas(
																currentPackageValue / 1e6
															)}
															)
														</div>{' '}
													</div>
													<div className='mx-4'>
														<b className='fs-3'>
															{isLimited ? '>' : '≦'}
														</b>
													</div>
													<div>
														<div>
															<b>Max CAP - Total Income</b>
														</div>
														<div>
															(${numberWithCommas(maxCap / 1e6)} - $
															{numberWithCommas(usedMaxCap / 1e6)} = $
															{numberWithCommas(
																(maxCap - usedMaxCap) / 1e6
															)}
															)
														</div>
													</div>
												</div>
												<h5 className='mt-4'>
													This month total profit: $
													{isLimited
														? numberWithCommas(xSum / 1e6)
														: numberWithCommas(sum)}
												</h5>
											</div>
										</div>
									</div>
								</div>
							)}
						</>
					}
				/>
			</div>
		</div>
	);
};

export default InvestmentDashboard;
